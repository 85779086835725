
// Pass backend variables to js variables
/**
 * VARIABLES AND FUNCTIONS THAT NEED INITIATION
 */
var windowSize = window.innerWidth
setBackgrounds();

/**
 * EVENT LISTENERS
 */

// Execute something on 575 breakpoint (mobile to desktop and vice versa)
window.addEventListener('resize', function(event) {
    setBackgrounds();
    windowSize = window.innerWidth;
}, true);

/**
 * FUNCTIONS
 */

// Get Sections Data and check if it has a background image
function setBackgrounds() {
    let images = document.querySelectorAll(`img[class^="auto-resize"]`);

    images.forEach(image => {
        let imageSrc = image && image.src ? image.src : null;
        if (imageSrc) {
            let urlWithSize = window.innerWidth <= 768 ? imageSrc.replace('?size=desktop', '?size=mobile') : imageSrc.replace('?size=mobile', '?size=desktop');
            image.src = urlWithSize;
        }

    });
}